<template>
  <div class="flex">
    <vs-row class="flex justify-between">
      <vs-col vs-w="2" :key="index" v-for="(data, index) in resources">
        <vx-card class="flex flex-wrap justify-center">
          <div class="w-full">
            <resourceData class="h-full" :data="data" />
          </div>
        </vx-card>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import resourceData from "./components/resources.vue";
export default {
  data() {
    return {
      resources: [],
    };
  },
  components: {
    resourceData,
  },
  methods: {
    getResourceReport() {
      this.$vs.loading();
      this.$http
        .get("/admin/haiovmresourcestatus/list")
        .then((res) => {
          this.$vs.loading.close();
          Object.keys(res.data).forEach((index) => {
            if (typeof res.data[index] == "object") {
              this.resources.push(res.data[index]);
            }
          });
          console.log(this.resources);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.getResourceReport();
  },
};
</script>

<style>
</style>