<template>
  <div>
    <div class="w-full flex flex-wrap justify-cener">
      <vs-button disabled color="" class="my-5 w-1/2 flex justify-center">
        RAM
        <p class="my-2">{{ formatBytes(data.mem) }} GB</p>
      </vs-button>
      <vs-button disabled color="" class="my-5 w-1/2 flex justify-center">
        CPU
        <p class="my-2">{{ formatBytes(data.cpu) / 10 }} CORE</p>
      </vs-button>
      <vs-divider></vs-divider>
      <div class="w-full flex justify-center">{{ data.node_name }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: { required: true },
  },
  data() {
    return {
      resourceData: "",
    };
  },
  methods: {
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return "0 Bytes";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return `${Math.round(parseFloat((bytes / Math.pow(k, i)).toFixed(dm)))}`;
    },
  },
};
</script>
<style>
</style>